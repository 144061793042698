import React from "react";

import Layout from "../components/layout";

import "./index.scss";

export default () => (
  <Layout>
    <div>
      <div id="section-home">
        <p>I'm <strong>Andrew Mahon</strong>, an engineering leader at <a class="external" href="http://www.affirm.com">Affirm</a> and previously founder and technical director of a <a class="external" href="https://typecode.com">boutique software agency</a>.</p>
        <p>I care about building expressive and extensible APIs that allow teams to move faster, communicate more effectively, and build the right thing.</p>
        <p>I enjoy adventures on bikes, skis, and feet and have an passion for maps and geography.</p>
      </div>
    </div>
  </Layout>
);
